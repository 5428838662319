import React, {useState, useEffect, useMemo} from 'react'
import {useApp} from '@store/app'
import {Table} from 'antd'
import {CeremonyDashboardProps, ProjectType} from '@components/dashboard/dashboard.t'
import styled from '@emotion/styled'
import {getColumnConfiguration} from '@components/dashboard/columnsConfig'
import _ from 'lodash'

export const CeremonyDashboard: React.FC<CeremonyDashboardProps> = ({
  data,
  taskCategories,
  taskCategoriesOrder,
  columnType,
  title,
  titleColor,
  headerColor,
  headerBgColor,
  isLoading
}) => {
  const {t} = useApp()

  const [scrollSize, setScrollSize] = useState({x: 1000})

  useEffect(() => {
    const calculateScrollSize = () => {
      const sizeX: number = window.innerWidth
      setScrollSize({x: sizeX - 100})
    }

    const debouncedCalculateScrollSize = _.debounce(calculateScrollSize, 300)

    calculateScrollSize()
    window.addEventListener('resize', debouncedCalculateScrollSize)

    return () => window.removeEventListener('resize', debouncedCalculateScrollSize)
  }, [])

  const finalColumns = useMemo(() => {
    return getColumnConfiguration(columnType, t, taskCategories, taskCategoriesOrder)
  }, [columnType, t, taskCategories, taskCategoriesOrder])

  const paginationConfig = {
    pageSize: 20
  }

  const StyledTable = styled(Table<ProjectType>)`
    .ant-table-thead > tr > th {
      background-color: ${headerBgColor};
      color: ${headerColor};
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      font-weight: 400;
      font-size: 12px;
    }

    .ant-table-header > table {
      width: auto !important;
      min-width: unset !important;
    }

    .ant-table-body > table {
      width: auto !important;
      min-width: unset !important;
    }

    .ant-table {
      display: inline-table !important;
      width: auto !important;
    }
  `

  return (
    <>
      <h5 className='dashboard-title' style={{color: titleColor}}>
        {title}
      </h5>
      <StyledTable
        className='dashboard-table'
        columns={finalColumns}
        dataSource={data}
        pagination={paginationConfig}
        loading={isLoading}
        scroll={scrollSize}
        tableLayout='fixed'
        size='small'
        sticky
      />
    </>
  )
}
