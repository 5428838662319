import {LookupItem} from '@shared/erp-api'
export interface ProjectType {
  project: {
    defunctName: string
    id: string
    _id: string
    date: string | null
    isPartial: boolean
    isComplete: boolean
    status: string
    statusColor: string
    ref: string
  }
  teamMembers: TeamMembers
  taskSummary: TaskSummaryType[]
}

export enum ProjectStatus {
  PASSED = 'passed',
  FUTURE = 'future'
}

export interface ColumnSize {
  defunct: string
  funerals: string
  team: string
  projectStatut: string
  completedSteps: string
  tasks: string
}
export interface TeamMembers {
  creator: PersonType | null
  officiant: PersonType | null
  facilitator: PersonType | null
  attendant: PersonType | null
}

export interface PersonType {
  firstname: string
  lastname: string
}

interface TaskSummaryType {
  category: {
    id: string
    shortName: string
    name: string
  }
  progress: number
}

export interface DataType {
  passedProjects: ProjectType[]
  futureProjects: ProjectType[]
}

export interface CeremonyDashboardProps {
  data?: ProjectType[]
  taskCategories: LookupItem[]
  taskCategoriesOrder?: LookupItem[]
  columnType: ProjectStatus
  title: string
  titleColor: string
  headerColor: string
  headerBgColor: string
  isLoading: boolean
}
