import {Tooltip} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {LookupItem} from '@shared/erp-api'
import {ProjectType} from '@components/dashboard/dashboard.t'
import {ProgressBar} from '@components/misc/progress-bar'

/**
 * All the categories that are postFuneral will be displayed in past ceremony dashboard. And the other will be send to future ceremony dashboard.
 * we need to construct a part of the dashboard columns from these categories and depending on isPostFuneral true|false
 * Also we only want to create tasks that are returned by the API (recoard.taskSummary)
 * @param {boolean} isPostFuneral - Filter tasks by postFuneral status.
 * @param {LookupItem[]} tasks - The tasks to transform.
 * @returns {Object[]} The transformed tasks suitable for dashboard columns.
 */
export const transformTasks = (
  isPostFuneral: boolean,
  tasks?: LookupItem[],
  tasksOrder?: LookupItem[],
  ColumnWidth?: string
): ColumnsType<ProjectType> => {
  if (!tasks) return []

  // sort tasks by checklist sequence order, if no taskOrder we keep the default order
  if (tasksOrder?.at(0)?.list) {
    tasks.sort((a, b) => {
      const order = tasksOrder?.at(0)?.list as string[]
      const indexOfA = order.indexOf(a._id)
      const indexOfB = order.indexOf(b._id)

      if (indexOfA === -1 || indexOfB === -1) return 0
      return indexOfA - indexOfB
    })
  }

  isPostFuneral
    ? (tasks = tasks?.filter((task) => task.isPostFuneral))
    : (tasks = tasks?.filter((task) => !task.isPostFuneral))

  return tasks.map((task) => ({
    title: (
      <Tooltip title={task.name?.toString()}>
        <span className='dashboard-header-title'>
          {task.shortName?.toString() ?? task.name?.toString()}
        </span>
      </Tooltip>
    ),
    width: ColumnWidth,
    key: task._id,
    render: (text: string, record: ProjectType) => {
      const taskSummary = record.taskSummary.find((summary) => summary.category.id === task._id) // find the task from /domain/categories that match checklist task.

      const progress: number = taskSummary ? taskSummary.progress : 0

      if (!taskSummary) return null

      return <ProgressBar percent={progress} />
    }
  }))
}
