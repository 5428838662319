import {useApp} from '@store/app'
import {useInmemoriServices} from '@services'
import {useQuery} from '@tanstack/react-query'
import {CeremonyDashboard} from '@components/dashboard/CeremonyDashboard'
import {useLookupQuery} from '@queries/domains'
import {DataType, ProjectStatus} from '@components/dashboard/dashboard.t'

interface CeremonyDashboardConfigs {
  key: keyof DataType
  columnType: ProjectStatus
  title: string
}

export const Dashboard = () => {
  const {t, isInitialized} = useApp()

  const erpApi = useInmemoriServices().erpApiService

  const dashboardData = useQuery({
    queryFn: () => erpApi.getDashboardData(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: isInitialized
  })

  //get categories of type "task" (because tasks column are dynamicaly created in the dashboard)
  const {
    data: taskCategories,
    error,
    isError
  } = useLookupQuery('categories', {limit: 30, q: {type: 'task'}}, {enabled: isInitialized})

  // taskCategories order is based on checklist sequence (_id: 6487446be0fd9ea248d7293f in /domain/sequences)
  const checklistSequenceId = '6487446be0fd9ea248d7293f'
  const {data: taskCategoriesOrder} = useLookupQuery('sequences', {
    limit: 15,
    q: {
      _id: checklistSequenceId
    }
  })

  if (isError || error) return <div className='dashboard-centered'>{t('dashboard.error')}</div>

  const ceremonyDashboardConfigs: CeremonyDashboardConfigs[] = [
    {
      key: 'futureProjects',
      columnType: ProjectStatus.FUTURE,
      title: 'Cérémonies à venir'
    },
    {
      key: 'passedProjects',
      columnType: ProjectStatus.PASSED,
      title: 'Cérémonies passées'
    }
  ]

  return (
    <div className='dashboard-container'>
      {ceremonyDashboardConfigs.map(({key, columnType, title}) => (
        <div className='dashboard-element' key={key}>
          <CeremonyDashboard
            data={dashboardData.data?.data?.data?.[key]}
            taskCategories={taskCategories?.data || []}
            taskCategoriesOrder={taskCategoriesOrder?.data}
            columnType={columnType}
            title={title}
            titleColor='#303133'
            headerColor='white'
            headerBgColor='#303133'
            isLoading={dashboardData.isLoading}
          />
        </div>
      ))}
    </div>
  )
}
