import Link from 'next/link'
import {LookupItem} from '@shared/erp-api'
import {transformTasks} from '@libs/dashboard'
import {ProjectType, ProjectStatus, ColumnSize} from './dashboard.t'
import {Tooltip} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {AvatarGroup} from '@components/misc/avatar-group'
import {IndicatorDot} from '@components/misc/indicator-dot'
import {TFunction} from 'i18next'

const columnsSize = {
  defunct: '12vw', // don't forget to update .dashboard-column-element
  funerals: '7vw',
  team: '8vw',
  projectStatut: '5vw',
  completedSteps: '7.5vw',
  tasks: '9vw'
}

const createCommonColumns = (t: TFunction): ColumnsType<ProjectType> => [
  {
    title: t('dashboard.defunct'),
    align: 'left',
    ellipsis: true,
    width: columnsSize.defunct,
    dataIndex: ['project', 'defunctName'],
    key: 'defunctName',
    render: (text: string, record: ProjectType) => {
      return (
        <div className='dashboard-column-element'>
          <Link
            href={{
              pathname: `/domain/projects/${record.project._id}`
            }}>
            <span title={text}>{text || record.project.ref}</span>
          </Link>
        </div>
      )
    }
  },
  {
    title: (
      <Tooltip title={t('dashboard.funeralsTooltip')}>
        <span>{t('dashboard.funerals')}</span>
      </Tooltip>
    ),
    align: 'left',
    width: columnsSize.funerals,
    dataIndex: ['project', 'date'],
    key: 'date',
    render: (text: string) =>
      text || (
        <span className='dashboard-unknown-date dashboard-column-element'>
          {t('dashboard.noDate')}
        </span>
      )
  },
  {
    title: t('dashboard.team'),
    align: 'left',
    width: columnsSize.team,
    key: 'teamMembers',
    render: (text: string, record: ProjectType) => {
      return <AvatarGroup team={record.teamMembers} />
    }
  },
  {
    title: (
      <Tooltip title={t('dashboard.projectStatutTooltip')}>
        <span>{t('dashboard.projectStatut')}</span>
      </Tooltip>
    ),
    align: 'left',
    width: columnsSize.projectStatut,
    dataIndex: ['project', 'status'],
    key: 'status',
    render: (text: string, record: ProjectType) => {
      return <IndicatorDot color={record.project.statusColor} text={record.project.status} />
    }
  },
  {
    title: (
      <Tooltip title={t('dashboard.completedStepsTooltip')}>
        <span>{t('dashboard.completedSteps')}</span>
      </Tooltip>
    ),
    align: 'left',
    width: columnsSize.completedSteps,
    dataIndex: ['project', 'isComplete'],
    key: 'isComplete',
    render: (text: string, record: ProjectType) => {
      return record.project.isComplete ? (
        '✓'
      ) : (
        <span className='dashboard-unknown-step dashboard-column-element'>
          {t('dashboard.noStep')}
        </span>
      )
    }
  }
]

const passedColumns = (
  t: TFunction,
  columnsSize: ColumnSize,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => [
  ...createCommonColumns(t),
  ...transformTasks(true, taskCategories, taskCategoriesOrder, columnsSize.tasks)
]

const futureColumns = (
  t: TFunction,
  columnsSize: ColumnSize,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => [
  ...createCommonColumns(t),
  ...transformTasks(false, taskCategories, taskCategoriesOrder, columnsSize.tasks)
]

export const getColumnConfiguration = (
  columnType: ProjectStatus,
  t: TFunction,
  taskCategories: LookupItem[],
  taskCategoriesOrder?: LookupItem[]
) => {
  if (columnType === ProjectStatus.PASSED) {
    return passedColumns(t, columnsSize, taskCategories, taskCategoriesOrder)
  } else if (columnType === ProjectStatus.FUTURE) {
    return futureColumns(t, columnsSize, taskCategories, taskCategoriesOrder)
  } else {
    return []
  }
}
